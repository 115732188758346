<template >
  <common-header :title="'SGT - ENE'" :isBg="true"></common-header>
  <div class="container">
    <div class="card-box">
      <div class="box-tit">
        <span>{{$t('全部质押 SGT 数量')}}</span>
      </div>
      <div class="box-num">
        <b>{{totalNum ? Bignumber(totalNum).dividedBy(Math.pow(10, decimals)).toFixed(4, 1) : '0.000'}}</b>
      </div>
      <div class="box-tit">
        <span>{{$t('我的质押 SGT 数量')}}</span>
      </div>
      <div class="box-num">
        <b>{{userInfos.lp ? Bignumber(userInfos.lp).dividedBy(Math.pow(10, decimals)).toFixed(4, 1) : '0.000'}}</b>
      </div>
      <div class="box-p w100">{{$t('当前挖出num数量', {num: 'ENE'})}}：{{Bignumber(reactiveNum).dividedBy(Math.pow(10, Tokendecimals)).toFixed(4, 1)}}ENE </div>
    </div>
    <div class="form-box">
      <span class="rec-link" @click="$router.push({name: 'OrderStaking', query: {planId: '2'}})">{{$t('质押记录')}}</span>
      <div class="box-tit flex-between">
        <span>
          {{$t('产出ENE日产量', {num: Number(planInfo.rate) / Number(planInfo.rateBase) * 100})}}
        </span>
      </div>
      <van-cell-group class="form-item">
        <van-field v-model="pledgeNum" type="number" :placeholder="$t('请输入质押SGT数量')" />
      </van-cell-group>
      <van-button v-if="!Number(LPAllowance)" class="btn-style" type="primary" @click="approveLp" :disabled="!planInfo.isOpen || disArrove" round block>{{$t('授权')}}</van-button>
      <van-button v-else class="btn-style" type="primary" @click="goPledge" :disabled="!planInfo.isOpen || disPledge" round block>{{$t('开始质押挖矿')}}</van-button>
    </div>
    <div class="list-title">{{$t('质押列表')}}</div>
    <div class="list" v-if="orderList">
      <div class="card-box box-shadow" v-for="(item, index) in orderList" :key="index">
        <div class="box-tit flex-between">
          <span>{{$t('已产出数量')}}</span>
        </div>
        <div class="box-num">
          <b v-if="Number(item.List.unlockTime) * 1000 > newTime && Number(item.List.endTime)">0.0000</b>
          <b v-else>{{Bignumber(item.earnNum).dividedBy(Math.pow(10, Tokendecimals)).toFixed(4, 1)}}</b>ENE
        </div>
        <div class="box-p">{{$t('质押SGT数量')}}：{{Bignumber(item.List.lpNumber).dividedBy(Math.pow(10, decimals)).toFixed()}} </div>
        <div class="box-p">
          {{$t('挖取ENE日产量', {num: Number(item.List.rate) / Number(item.List.rateBase) * 100})}}
        </div>
        <div class="box-p">{{$t('可提取時間')}}：{{$dateformat(Number(item.List.unlockTime) * 1000, 'yyyy-mm-dd')}}</div>
        <div class="btns">
          <van-button class="btn-style" v-if="!Number(item.List.endTime)" type="primary" @click="goUnpledgeLp(index)" :disabled="disUnpledgeLp" round block>{{$t('解押')}}</van-button>
          <van-button class="btn-style" v-if="Number(item.List.unlockTime) * 1000 < newTime && !!Number(item.earnNum)" type="primary" @click="getReward(index)" :disabled="disReward" round block>{{$t('领取')}}ENE</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonHeader from '@/components/CommonHeader'
import { reactive, toRefs, computed, watch } from "vue";
import { Toast } from 'vant';
import {useStore} from "vuex";
import { useI18n } from "vue-i18n";
import { config } from "@/utils/config";
import stakingAbi from "@/utils/staking";
import tokenAbi from "@/utils/abi";
import { MaxUint256 } from "@ethersproject/constants"
import Bignumber from "bignumber.js"
export default {
  components: {
    CommonHeader
  },
  setup() {
    const store = useStore()
    const { t } = useI18n()
    const data = reactive({
      $t: t,
      address: computed(() => store.state.accounts[0]),
      web3: computed(() => store.state.provider),
      Bignumber: Bignumber,
      PledgeContract: null,
      TokenContract: null,
      ENETokenContract: null,
      pledgeNum: '',
      decimals: '',
      Tokendecimals: '',
      LPAllowance: '',
      balanceOf: '',
      reactiveNum: 0.0000,
      planInfo: [],
      totalNum: '',
      userInfos: {},
      orderList: [],
      disArrove: false,
      disPledge: false,
      disReward: false,
      disUnpledgeLp: false,
      newTime: parseInt(Date.now()),
      initContract: () => {
        data.PledgeContract = new data.web3.eth.Contract(stakingAbi, config.StakingAddress)
        data.TokenContract = new data.web3.eth.Contract(tokenAbi, config.SGTTokenAddress)
        data.ENETokenContract = new data.web3.eth.Contract(tokenAbi, config.ENETokenAddress)
        data.initData()
      },
      initData: async () => {
        console.log(data.newTime)
        data.getPlan()
        data.getTokenInfo()
        data.isapprove()

        // await data.PledgeList()
        data.getPledgePeriodly()
      },
      getPledgePeriodly: async (timer = 10000) => {
        const result = await data.PledgeList()
        if (result) {
          await new Promise(resolve => setTimeout(resolve, timer))
          data.getPledgePeriodly(timer)
        }
      },
      getTokenInfo: async() => {
        data.decimals = await data.TokenContract.methods.decimals().call()
        data.Tokendecimals = await data.ENETokenContract.methods.decimals().call()
        console.log(data.decimals, data.Tokendecimals)
        data.balanceOf = await data.TokenContract.methods.balanceOf(data.address).call()
      },
      getPlan: async() => {
        data.planInfo = await data.PledgeContract.methods.getPlan('2').call()
        console.log(data.planInfo)
        data.totalNum = await data.PledgeContract.methods.totalLp().call()
        data.userInfos = await data.PledgeContract.methods.userInfos(data.address).call()
        console.log(data.userInfos)
      },
      PledgeList: async() => {
        // data.orderList = []
        let num = await data.PledgeContract.methods.getOrderNum(data.address, '2').call()
        // console.log(num)
        if (!Number(num)){
          return
        }
        let total = 0
        for (var i = 0; i < Number(num); i++) {
          let List = await data.PledgeContract.methods.getOrder(data.address, '2', i).call()
          
          let earnNum = await data.PledgeContract.methods.earn(data.address, '2', i).call()
          // console.log(earnNum, List.received)
          let allReward = Number(earnNum) + Number(List.received)
          const index = data.orderList.findIndex(item => item.List.buyTime === List.buyTime)
          if (index > -1) {
            data.orderList[index] = {List, allReward: allReward, earnNum: earnNum}
          } else {
            data.orderList.push({List, allReward: allReward, earnNum: earnNum})
          }
          // console.log(data.orderList, Number(List.unlockTime) * 1000 , data.newTime, !Number(List.endTime))
          // if(Number(List.unlockTime) * 1000 > data.newTime && !Number(List.endTime)) {
          //   console.log(2222)
          //   data.reactiveNum = data.reactiveNum + allReward
          // }
          console.log(data.reactiveNum)
          total = total + Number(List.received)
        }
        data.reactiveNum = total
        return true
      },
      approveLp: async () => {
        data.disArrove = true
        await data.TokenContract.methods.approve(config.StakingAddress, MaxUint256).send({from: data.address})
        .then(() =>{
          data.disArrove = false
        })
        .catch(() =>{
          data.disArrove = false
        })
        data.isapprove()
      },
      isapprove: async () => {
        data.LPAllowance = await data.TokenContract.methods.allowance(data.address, config.StakingAddress).call()
        console.log(data.LPAllowance)
      },
      goPledge: async() => {
        console.log(data.pledgeNum)
        data.disPledge = true
        if(!data.pledgeNum || Number(data.pledgeNum) <= 0){
          Toast(t('输入数量需大于0'))
          data.disPledge = false
          return
        }
        let amount = new Bignumber(data.pledgeNum).multipliedBy(Math.pow(10, data.decimals)).toFixed()
        if(Bignumber(amount).gt(data.balanceOf)){
          Toast(t('输入数量大于余额'))
          data.disPledge = false
          return
        }
        let gas = await data.PledgeContract.methods.pledgeLp('2', amount).estimateGas({from: data.address})
        await data.PledgeContract.methods.pledgeLp('2', amount).send({from: data.address, gas: gas})
        .then((res)=>{
          Toast(t('质押成功'))
          data.disPledge = false
          data.initData()
        })
        .catch(() =>{
          data.disPledge = false
        })
      },
      getReward: async(id) => {
        data.disReward = true
        let gas = await data.PledgeContract.methods.getReward('2', id).estimateGas({from: data.address})
        await data.PledgeContract.methods.getReward('2', id).send({from: data.address, gas: gas})
        .then((res)=>{
          Toast(t('领取成功'))
          data.disReward = false
          data.initData()
        })
        .catch(() =>{
          data.disReward = false
        })
      },
      goUnpledgeLp: async(id) => {
        data.disUnpledgeLp = true
        let gas = await data.PledgeContract.methods.unpledgeLp('2', id).estimateGas({from: data.address})
        await data.PledgeContract.methods.unpledgeLp('2', id).send({from: data.address, gas: gas})
        .then((res)=>{
          Toast(t('解押成功'))
          data.disUnpledgeLp = false
          data.initData()
        })
        .catch(() =>{
          data.disUnpledgeLp = false
        })
      }
    })
    if (data.address) {
      data.initContract()
    }
     watch(() => data.address, (val) => {
      if (val) {
        data.initContract()
      }
    })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
}
</script>
<style lang="less" scoped>
.container {
  background: url('~@/assets/images/bg01.png') no-repeat top center;
  background-size: 100% auto;
  background-attachment: fixed;
  .list-title {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    line-height: 25px;
    padding: 15px 25px;
  }
  .form-box {
    margin: 0 12px 12px;
    background: #EEF5F6;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 1px 13px 0px #383444;
    .box-tit {
      font-size: 13px;
      color: @deep-color;
      line-height: 23px;
    }
    .form-item {
      padding: 10px 12px;
      background: #93B6BC;
      border-radius: 10px;
      margin-top: 12px;
      .van-cell {
        background: transparent;
      }
    }
    .btn-style {
      font-size: 15px;
      margin-top: 16px;
    }
  }
  .card-box {
    margin: 0 12px 12px;
    background: #EEF5F6;
    border-radius: 10px;
    padding: 12px;
    position: relative;
    &.box-shadow {
      box-shadow: 0px 1px 13px 0px #383444;
    }
    .box-tit {
      font-size: 13px;
      color: @deep-color;
      line-height: 23px;
    }
    .box-num {
      font-size: 13px;
      color: @theme-color;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 8px;
      b {
        font-size: 25px;
        word-wrap: break-word;
      }
    }
    .box-p {
      font-size: 13px;
      color: @deep-color;
      line-height: 23px;
      width: 60%;
      word-wrap: break-word;
    }
    .btns{
      position: absolute;
      right: 12px;
      bottom: 12px;
      .btn-style{
        height: 36px;
        padding: 0 22px;
        &:nth-child(2){
          margin-top: 10px;
        }
      }
    }
  }
}
.rec-link{
  width: 100%;
  display: block;
  font-size: 13px;
  color: @deep-color;
  line-height: 23px;
  text-align: right;
}
</style>
<style lang="less">
.form-item {
  .van-field__control {
    color: #fff !important;
    font-size: 13px;
    &::placeholder {
      color: #EBEBEB!important;
    }
  }
}
</style>
